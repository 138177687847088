import React, { useCallback, useEffect } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { RouteLayout } from "./Components";
import WithHeader from "./Components/Layouts/WithHeader";
import Home from "./Pages/Home";
import Flow from "./Pages/Flow";
import Requests from "./Pages/Requests";
import LoginCallback from "./Pages/LoginCallback";
import Information from "./Pages/Information";
import ManageFlow from "./Pages/Flow/components/ManageFlow";
import ConfigurationDocument from "./Pages/Information/components/ConfigurationDocument";
import ManageForm from "./Pages/Information/components/ManageForm"
import Build from "./Pages/Build";
import ProcessingForm from "./Pages/Processing";
import RedirectRequest from "./Pages/RequestDetails";
import NewProceed from "./Pages/NewProceed";
import NewDocuments from "./Pages/NewDocument";
import Organ from "./Pages/Organ";
import OrganInfo from "./Pages/OrganInformation";
import ManageHeader from "./Pages/OrganInformation/ManageHeader";
import AttachDocument from "./Pages/AttachDocument";
import DocVisualization from "./Pages/DocVisualization";
import VisualizationDocumentResponse from "./Pages/NewDocument/Components/VisualizationDocument";
import BlankLayout from "./Components/Layouts/BlankLayout";
import InitPage from "./Pages/InitPage";
import CopyFlow from "./Pages/Flow/components/CopyFlow";
import { useGetProfileType } from "./hooks/useGetProfileType";
import reducer, { initialState } from "./store/reducer";
import { StateProvider } from "./providers/StateProvider";
import NewTerm from "./Pages/NewTerm";
import TempForm from "./Pages/TempForm";
import RedirectForm from "./Pages/RedirectForm";
import DefaultRequestPath from "./Pages/DefaultRequestPath";
import RequestSimplePath from "./Pages/RequestSimpleDetails";
import Forms from "./Pages/Forms";
import RedirectRequestDetails from "./Pages/RedirectRequestDetails";
import ManageWorkflow from "./Pages/ManageWorkflow";

function App() {
  const verifyAllowed = useGetProfileType();

  useEffect(() => {
    document.title = process.env.REACT_APP_TITLE || 'D.Gov';
    let link: any = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = `/${process.env.REACT_APP_FAVICON || 'favicon.ico'}`;
  }, []);

  const PrivateRoute = useCallback(({ component: Component, layout: Layout, ...rest }) => {
    return (
      <Route
        {...rest}
        render={props => (
          !verifyAllowed ? (
            <Layout>
              <Component {...props} />
            </Layout>
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location }
              }}
            />
          )
        )}
      />
    )
  }, [verifyAllowed]);


  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <BrowserRouter>
        <Switch>
          <Route path="/login/callback/" component={LoginCallback} />
          <RouteLayout
            path="/"
            exact
            component={InitPage}
            layout={BlankLayout}
            isAuth
          />
          <RouteLayout path="/home" exact component={Home} layout={WithHeader} isAuth />
          <RouteLayout path="/solicitacoes" exact component={Requests} layout={WithHeader} isAuth />
          <RouteLayout path="/solicitacoes/cadastra-tramite/:identificador" exact component={NewProceed} layout={WithHeader} isAuth />
          <RouteLayout path="/solicitacoes/:solicitacao_id/:orgao_id/documento/:solicitacao/cadastrar-documento" exact component={NewDocuments} layout={WithHeader} isAuth />
          <RouteLayout path="/solicitacoes/:solicitacao_id/:orgao_id/documento/:solicitacao/editar-documento/:id_documento" exact component={NewDocuments} layout={WithHeader} isAuth />
          <RouteLayout path="/solicitacoes/:solicitacao_id/:orgao_id/documento/:id_document/visualizar" exact component={VisualizationDocumentResponse} layout={WithHeader} isAuth />
          <RouteLayout path="/solicitacoes/:solicitacao_id/:orgao_id/cadastra-documento/:id_documento" exact component={NewDocuments} layout={WithHeader} isAuth />
          <RouteLayout path="/solicitacoes/:identificador/:id/anexar-documento" exact component={AttachDocument} layout={WithHeader} isAuth />
          <RouteLayout path="/solicitacoes/detalhes/:identificador/:enviado?" exact component={RedirectRequest} layout={WithHeader} isAuth />
          <RouteLayout path="/solicitacoes/detalhes/:identificador/visualizar-simples/:protocolo_atendimento?" exact component={RequestSimplePath} layout={BlankLayout} />
          <RouteLayout path="/solicitacoes/detalhes/:identificador/visualizar" exact component={DefaultRequestPath} layout={WithHeader} isAuth />
          <RouteLayout
            path="/solicitacoes/documento/:id/:identificador/visualizar-documentos-simples"
            exact
            component={DocVisualization}
            layout={BlankLayout}
            isAuth
          />
          <RouteLayout
            path="/solicitacoes/documento/:id/:identificador/visualizar-documentos"
            exact
            component={DocVisualization}
            layout={WithHeader}
            isAuth
          />
          <RouteLayout
            path="/solicitacoes"
            exact
            component={Requests}
            layout={WithHeader}
            isAuth
          />
          <PrivateRoute path="/fluxo" exact component={Flow} layout={WithHeader} />
          <PrivateRoute
            path="/orgaos"
            exact
            component={Organ}
            layout={WithHeader}
            isAuth
          />
          <PrivateRoute
            path="/orgaos/:orgao_id"
            exact
            component={OrganInfo}
            layout={WithHeader}
            isAuth
          />
          <PrivateRoute
            path="/orgaos/:orgao_id/criar-cabecalho"
            exact
            component={ManageHeader}
            layout={WithHeader}
            isAuth
          />
          <PrivateRoute
            path="/orgaos/:id/:orgao_id/editar"
            exact
            component={ManageHeader}
            layout={WithHeader}
            isAuth
          />
          <PrivateRoute
            path="/informacoes/:flow_slug"
            exact
            component={Information}
            layout={WithHeader}
            isAuth
          />
          <PrivateRoute
            path="/fluxo/cadastrar-fluxo"
            exact
            component={ManageFlow}
            layout={WithHeader}
            isAuth
          />
          <PrivateRoute
            path="/fluxo/copiar-fluxo/:flow_slug"
            exact
            component={CopyFlow}
            layout={WithHeader}
            isAuth
          />
          <PrivateRoute
            path="/fluxo/:flow_slug/editar"
            exact
            component={ManageFlow}
            layout={WithHeader}
            isAuth
          />
          <PrivateRoute
            path="/fluxo/:flow_slug/cadastrar-formulario"
            exact
            component={ManageForm}
            layout={WithHeader}
            isAuth
          />
          <PrivateRoute
            path="/fluxo/:flow_slug/form/:form_slug/editar"
            exact
            component={ManageForm}
            layout={WithHeader}
            isAuth
          />
          <PrivateRoute
            path="/fluxo/formulario/:form_slug/:flow_slug/construir"
            exact
            component={Build}
            layout={WithHeader}
            isAuth
          />
          <PrivateRoute
            path="/fluxo/:flow_slug/tramitacao/construir"
            exact
            component={ProcessingForm}
            layout={WithHeader}
            isAuth
          />
          <PrivateRoute
            path="/fluxo/:flow_slug/termos/novo/:term_id?"
            exact
            component={NewTerm}
            layout={WithHeader}
            isAuth
          />
          <PrivateRoute
            path="/fluxo/:flow_slug/workflow/salvar/:workflow_id?"
            exact
            component={ManageWorkflow}
            layout={WithHeader}
            isAuth
          />
          <PrivateRoute
            path="/fluxo/:flow_slug/tramitacao/gerenciar"
            exact
            component={ProcessingForm}
            layout={WithHeader}
            isAuth
          />
          <PrivateRoute
            path="/fluxo/:flow_slug/documento/orgao/:orgao_id/configurar"
            exact
            component={ConfigurationDocument}
            layout={WithHeader}
            isAuth
          />
          <PrivateRoute
            path="/fluxo/:flow_slug/documento/orgao/:orgao_id/configurar/:id_document"
            exact
            component={ConfigurationDocument}
            layout={WithHeader}
            isAuth
          />
          <Route
            path="/carregar-formularios/"
            exact
            component={RedirectForm}
          />
          <Route
            path="/carregar-solicitacao/"
            exact
            component={RedirectRequestDetails}
          />
          <Route
            path="/formularios/"
            exact
            component={TempForm}
          />
          <Route
            path="/formularios-atendente/:servico_slug/:id/:identificador/:protocolo_atendimento?"
            exact
            component={Forms}
          />
        </Switch>
      </BrowserRouter>
    </StateProvider>
  );
}

export default App;
