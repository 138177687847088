import { useEffect, useMemo, useState } from 'react';
import { TypeProfile } from '../interfaces/permissions';
import { getMyPermissions } from '../services/flow';
import getCookie from '../utils/getCookies';

export function useGetProfileType() {
  const [typeProfileActive, setTypeProfileActive] = useState<TypeProfile>('Administrador');

  useEffect(() => {
    async function handleGetMyPermissions() {
      try {
        const token: string | null = getCookie("gov_access_token");
        const { data } = await getMyPermissions(token);

        setTypeProfileActive(data.perfil);
      } catch (err) {
        console.log(err);
      }
    }

    handleGetMyPermissions();
  }, []);


  const verifyProfileAllowed = useMemo(() =>
  (typeProfileActive === 'Coordenador' ||
    typeProfileActive === 'Atendente'),
    [typeProfileActive]);

  return verifyProfileAllowed;
}