import React, { useEffect, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";
import {
  getImageProfile,
  logout,
  setCookie,
} from "../../services/auth";
import { useStateValue } from "../../providers/StateProvider";
import {
  BoxLogo,
  Content,
  BoxActions,
  CustomNavBar,
  ItemMenu,
  ButtonDropDown,
  MenuDropDown,
  ButtonLogin,
  BoxUser,
  IconUser,
  Avatar,
  TextUser,
  TextCpf,
  ButtonLogout,
  IconLogout,
  NavMenu,
  SideBar,
  TitleSideBar,
  MenuSideBar,
  ItemMenuSidebar,
  CloseSideBar,
  BoxUserButton,
} from "./style";
import {
  MdAccountTree,
  MdHome,
  MdOutlinePostAdd,
  MdAccountBalance,
} from "react-icons/md";
import { Dropdown, Navbar } from "react-bootstrap";
import { nameSplitTwo } from "../../utils/nameFormat";
import { cpfMask } from "../../utils/cpfFormat";
import Loading from "../Loading";
import ToastMessage from "../ToastMessage";
import { useGetProfileType } from "../../hooks/useGetProfileType";
import getCookie from "../../utils/getCookies";

export default function Header(): JSX.Element {
  const history = useHistory();

  const { pathname, search } = useLocation();
  const [avatarUser, setAvatarUser] = useState("");
  const [loading, setLoading] = useState(false);
  const [sidebar, setSideBar] = useState(false);
  const verifyProfileAllowed = useGetProfileType();
  const [{ user, flashMessage }] = useStateValue();

  const logoutUser = () => {
    logout();
  };

  useEffect(() => {
    if (!user) {
      setCookie("pathname_redirect_gov", pathname + search, 1);
      history.push("/");
    }
  }, [user, history, pathname, search]);

  useEffect(() => {
    const token: string | null = getCookie(
      "gov_access_token"
    );
    if (token) {
      const handleGetImageProfile = async () => {

        setLoading(true);
        const { data } = await getImageProfile(token);

        if (data?.results?.length > 0) {
          const image = data.results[0].arquivo;
          setAvatarUser(image);
        }
        setLoading(false);
      };
      handleGetImageProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showSideBar = () => {
    setSideBar(!sidebar);
  };
  return (
    <CustomNavBar>
      {flashMessage && <ToastMessage />}
      <Content>
        <BoxLogo>{process.env.REACT_APP_NAME_APP || "Formflow"} </BoxLogo>
        <NavMenu>
          <ItemMenu to="/home">
            <MdHome size={20} /> Início
          </ItemMenu>
          {!verifyProfileAllowed && (
            <ItemMenu to="/fluxo">
              <MdAccountTree size={20} /> Fluxo
            </ItemMenu>
          )}
          <ItemMenu to="/solicitacoes">
            <MdOutlinePostAdd size={20} /> Solicitações
          </ItemMenu>
          {!verifyProfileAllowed && (
            <ItemMenu to="/orgaos">
              <MdAccountBalance size={20} /> Órgãos
            </ItemMenu>
          )}
        </NavMenu>
        <BoxActions>
          <Navbar.Toggle onClick={showSideBar} style={{ marginRight: 10, padding: '4px 8px' }} />
          {/* <BoxNotification>
            <MdCircleNotifications size={32} style={{ color: "#fff" }} />
            <LabelNotification>2</LabelNotification>
          </BoxNotification> */}
          {loading ? (
            <Loading />
          ) : (
            <>
              {user ? (
                <Dropdown align="end">
                  <ButtonDropDown>
                    <Avatar src={avatarUser} />
                    <BoxUserButton>
                      <TextUser>
                        {nameSplitTwo(user?.nome).toLowerCase()}
                      </TextUser>
                      <TextCpf>{user?.cpf}</TextCpf>
                    </BoxUserButton>
                  </ButtonDropDown>
                  <MenuDropDown>
                    <Dropdown.ItemText>
                      <BoxUser>
                        <TextUser
                          style={{
                            color: "#1E212A",
                            fontSize: 16,
                          }}
                        >
                          {nameSplitTwo(user?.nome).toLowerCase()}
                        </TextUser>
                        <TextCpf style={{ color: "#50617", fontSize: 14 }}>
                          {cpfMask(user?.cpf)}
                        </TextCpf>
                      </BoxUser>
                    </Dropdown.ItemText>
                    <Dropdown.Divider />
                    <ButtonLogout onClick={logoutUser}>
                      <IconLogout />
                      Desconectar
                    </ButtonLogout>
                  </MenuDropDown>
                </Dropdown>
              ) : (
                <ButtonLogin>
                  <IconUser />
                  Acessar
                </ButtonLogin>
              )}
            </>
          )}
        </BoxActions>
        <SideBar show={sidebar} onHide={showSideBar}>
          <TitleSideBar>
            Menu
            <CloseSideBar onClick={showSideBar} />
          </TitleSideBar>
          <MenuSideBar>
            <ItemMenuSidebar to="/home" onClick={showSideBar}>
              <MdHome size={20} /> Início
            </ItemMenuSidebar>
            <ItemMenuSidebar to="/fluxo" onClick={showSideBar}>
              <MdAccountTree size={20} /> Fluxo
            </ItemMenuSidebar>
            <ItemMenuSidebar to="/solicitacoes" onClick={showSideBar}>
              <MdOutlinePostAdd size={20} /> Solicitações
            </ItemMenuSidebar>
            <ItemMenuSidebar to="/orgaos" onClick={showSideBar}>
              <MdAccountBalance size={20} /> Órgãos
            </ItemMenuSidebar>
          </MenuSideBar>
        </SideBar>
      </Content>
    </CustomNavBar>
  );
}
